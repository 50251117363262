// eslint-disable-next-line max-classes-per-file
import { ClusterLocation, Template } from "../core/Models";
import { BaseModel } from "modules/base/models/Base";

/**
 * Virtual Machine Location Model
 */
export class VirtualMachineLocation extends ClusterLocation {
  constructor(data: any = {}) {
    super(data);
  }
}

/**
 * Virtual Machine Template Model
 */
export class VirtualMachineTemplate extends Template {
  root_disk_size: number;

  constructor(data: any = {}) {
    super(data);
    this.root_disk_size = data.root_disk_size;
  }
}

/**
 * Virtual Machine Backup Template Model
 */
export class VirtualMachineBackupTemplate extends BaseModel {
  id: number;

  name: string;

  description: string;

  hourly_frequency: number;

  daily_frequency: number;

  weekly_frequency: number;

  monthly_frequency: number;

  monthly_plan_amount: number;

  plan_currency: string;

  plan_price_display: string;

  billing_plan: number;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.hourly_frequency = data.hourly_frequency;
    this.daily_frequency = data.daily_frequency;
    this.weekly_frequency = data.weekly_frequency;
    this.monthly_frequency = data.monthly_frequency;
    this.monthly_plan_amount = data.monthly_plan_amount;
    this.plan_currency = data.plan_currency;
    this.plan_price_display = data.plan_price_display;
    this.billing_plan = data.billing_plan;
  }
}

/**
 * Virtual Machine Model
 */
export class VirtualMachineImage extends BaseModel {
  id: number;

  name: string;

  os_type: string;

  os_version: string;

  location_region: string;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.os_type = data.os_type;
    this.os_version = data.os_version;
    this.location_region = data.location_region;
  }
}

/**
 * Virtual Machine Model
 */
export class VirtualMachine extends BaseModel {
  id: number;

  name: string;

  cpu: number;

  memory: number;

  root_disk_size: number;

  bandwidth: number;

  ip_address: string;

  location_country_code: string;

  location: number;

  state: string;

  state_reason: string;

  raw_state: string;

  vnc_auth_token: string;

  vnc_url: string;

  os_version: string;

  hostname: string;

  template: number;

  backup_template: number;

  image: number;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.cpu = data.cpu;
    this.memory = data.memory;
    this.root_disk_size = data.root_disk_size;
    this.bandwidth = data.bandwidth;
    this.ip_address = data.ip_address;
    this.location_country_code = data.location_country_code;
    this.location = data.location;
    this.state = data.state;
    this.state_reason = data.state_reason;
    this.raw_state = data.raw_state;
    this.vnc_auth_token = data.vnc_auth_token;
    this.vnc_url = data.vnc_url;
    this.os_version = data.os_version;
    this.hostname = data.hostname;
    this.template = data.template;
    this.backup_template = data.backup_template;
    this.image = data.image;
  }
}

/**
 * Virtual Machine Backup Model
 */
export class VirtualMachineBackup extends BaseModel {
  id: number;

  name: string;

  created_at: string;

  ready_to_use: boolean;

  virtual_machine: number;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.created_at = data.created_at;
    this.ready_to_use = data.ready_to_use;
    this.virtual_machine = data.virtual_machine;
  }
}
