import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import AppsApiActions from "modules/apps/ApiActions";
import { FULL_PATH_LIST_INSTALLED_APPS } from "modules/apps/Contants";
import ActionsDropdown from "modules/base/components/ActionsDropdown";

function VirtualMachineActions(props) {
  const appsApiActions = new AppsApiActions();
  const { subject_id } = props;
  const navigate = useNavigate();
  const actions_list = [
    {
      label: "Delete",
      icon: "trash",
      onClick: (id, successCallback, errorCallback) => {
        appsApiActions.deleteInstalledApp(
          id,
          () => {
            navigate(FULL_PATH_LIST_INSTALLED_APPS);
            successCallback();
          },
          (error) => errorCallback("Error deleting app", error),
        );
      },
      showConfirm: true,
      confirm: {
        title: "Delete this app?",
        message:
          "This action cannot be undone. All data will be permanently deleted.",
        confirm_button_label: "Delete",
        action_done_title: "Deleted",
        action_done_message: "The app has been deleted.",
      },
    },
  ];
  return (
    <ActionsDropdown
      label="Actions"
      actionsList={actions_list}
      subject_id={subject_id}
    />
  );
}

VirtualMachineActions.propTypes = {
  subject_id: PropTypes.number.isRequired,
};

export default VirtualMachineActions;
