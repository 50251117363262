import { useEffect, useState } from "react";
import NavigationLink from "modules/base/components/NavigationLink";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import Pagination from "modules/servers/components/Pagination";
import VirtualMachineActions from "modules/servers/components/VirtualMachineActions";
import VirtualMachineCard from "modules/servers/components/VirtualMachineCard";
import {
  PATH_DEPLOY_VM,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";

/**
 * VirtualMachinesList component
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachinesList() {
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 5;
  const serversApiActions = new ServersApiActions();
  let { vms, count, error, isLoading } = serversApiActions.getVirtualMachines(
    limit,
    page
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  const updatedData = serversApiActions.getVirtualMachinesWithState(
    limit,
    page,
    mounted
  );
  if (!updatedData.isLoading) {
    vms = updatedData.vms;
    count = updatedData.count;
    error = updatedData.error;
    isLoading = updatedData.isLoading;
  }
  const vm_deploy_path = `${PATH_VIRTUAL_MACHINES}/${PATH_DEPLOY_VM}`;
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const virtualMachines = vms.map((vm) => {
    return (
      <VirtualMachineCard
        virtual_machine={vm}
        key={vm.id}
        actions={<VirtualMachineActions subject_id={vm.id} row={vm} />}
      />
    );
  });
  const launchVMButton: Element = (
    <div className="d-grid justify-content-md-end mb-2">
      <NavigationLink path={vm_deploy_path} classes="d-grid">
        <button type="button" className="btn btn-purple flex-sm-grow-1">
          <i className="bi bi-rocket-takeoff" /> Launch a VM
        </button>
      </NavigationLink>
    </div>
  );
  const showPagination = count > limit;
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="flex flex-col">
        {launchVMButton}
        <div className="flex flex-row flex-wrap">{virtualMachines}</div>
        {showPagination && (
          <div className="text-center mt-3 mb-5">
            <Pagination
              setPage={setPage}
              pageSize={limit}
              pageIndex={page}
              count={count}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default VirtualMachinesList;
