import React from "react";
import AppsApiActions from "modules/apps/ApiActions";
import VirtualMachineActions from "modules/apps/components/InstalledAppActions";
import InstalledAppCard from "modules/apps/components/InstalledAppCard";
import {
  PATH_APPS,
  PATH_AVAILABLE_APPS,
  PATH_LIST_INSTALLED_APPS,
} from "modules/apps/Contants";
import NavigationLink from "modules/base/components/NavigationLink";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";

function InstalledAppsList() {
  const appsApiActions = new AppsApiActions();
  const { installed_apps, error, isLoading } =
    appsApiActions.getInstalledApps();
  const available_apps_path = `${PATH_APPS}/${PATH_AVAILABLE_APPS}/${PATH_LIST_INSTALLED_APPS}`;

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  const installedApps = installed_apps.map((app) => {
    return (
      <InstalledAppCard
        key={app.id}
        actions={<VirtualMachineActions subject_id={app.id} />}
        isClickable={false}
        installed_app={app}
      />
    );
  });

  const launchAppButton: Element = (
    <div className="d-grid justify-content-md-end mb-2">
      <NavigationLink path={available_apps_path} classes="d-grid">
        <button type="button" className="btn btn-purple flex-sm-grow-1">
          <i className="bi bi-rocket-takeoff" /> Launch App
        </button>
      </NavigationLink>
    </div>
  );

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="flex flex-col">
        {launchAppButton}
        <div className="flex flex-row flex-wrap">{installedApps}</div>
      </div>
    </>
  );
}

export default InstalledAppsList;
