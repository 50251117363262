import {
  AVAILABLE_APP_TEMPLATES,
  URL_AVAILABLE_APP,
  URL_AVAILABLE_APPS,
  URL_AVAILABLE_APPS_LOCATIONS,
  URL_AVAILABLE_APP_VERSIONS,
  URL_CONNECT_DOMAIN,
  URL_INSTALLED_APP,
  URL_INSTALLED_APPS,
} from "modules/apps/Contants";
import {
  AvailableAppTemplate,
  AvailableAppVersion,
  AvailableApps,
  AvailableAppsLocation,
  InstalledApp,
} from "modules/apps/Models";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import PrivateApi from "modules/core/utilities/PrivateApi";

export default class AppsApiActions extends PrivateApi {
  /**
   * Get installed apps
   * @returns {{isLoading: boolean, installed_apps: *[], error: any}}
   */
  getInstalledApps() {
    const { data, error, isLoading } = this.getAPIRequest(
      URL_INSTALLED_APPS,
      10000,
    );
    const installedApp = new InstalledApp();
    const installed_apps = installedApp.fromArray(data);
    return { installed_apps, error, isLoading };
  }

  /**
   * Get available apps
   * @returns {{isLoading: boolean, available_apps: *[], error: any}}
   */
  getAvailableApps() {
    const url = `${URL_AVAILABLE_APPS}?enabled=true`;
    const { data, error, isLoading } = this.getAPIRequest(url);
    const availableApps = new AvailableApps();
    const available_apps = availableApps.fromArray(data);
    return { available_apps, error, isLoading };
  }

  /**
   * Get available app
   * @param app_id
   * @returns {{isLoading: boolean, available_app: {}, error: any}}
   */
  getAvailableApp(app_id) {
    const url = serverGeneratePath(URL_AVAILABLE_APP, { id: app_id });
    const { data, error, isLoading } = this.getAPIRequest(url);
    const available_app = new AvailableApps(data);
    return { available_app, error, isLoading };
  }

  /**
   * Get available app versions
   * @param app_id
   * @returns {{isLoading: boolean, available_app_versions: *[], error: any}}
   */
  getAvailableAppVersions(app_id) {
    const url = `${URL_AVAILABLE_APP_VERSIONS}?kube_app=${app_id}&enabled=true`;
    const { data, error, isLoading } = this.getAPIRequest(url);
    const availableAppVersions = new AvailableAppVersion();
    const versions = availableAppVersions.fromArray(data);
    return { versions, error, isLoading };
  }

  /**
   * Get available apps locations
   * @returns {{isLoading: boolean, available_app_locations: *[], error: any}}
   */
  getAvailableAppsLocations() {
    const { data, error, isLoading } = this.getAPIRequest(
      URL_AVAILABLE_APPS_LOCATIONS,
    );
    const availableAppLocations = new AvailableAppsLocation();
    const locations = availableAppLocations.fromArray(data);
    return { locations, error, isLoading };
  }

  /**
   * Get available app templates
   * @param app_id
   * @param cluster_ids
   * @returns {{isLoading: boolean, available_app_templates: *[], error: any}}
   */
  getAvailableAppTemplates(app_id, cluster_ids = null) {
    let url = `${AVAILABLE_APP_TEMPLATES}?kube_apps=${app_id}`;
    url = cluster_ids ? `${url}&cluster_ids=${cluster_ids}` : url;
    const { data, error, isLoading } = this.getAPIRequest(url);
    const availableAppTemplates = new AvailableAppTemplate();
    const templates = availableAppTemplates.fromArray(data);
    return { templates, error, isLoading };
  }

  /**
   * Create installed app
   * @param {Object} payload
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  createInstalledApp(payload, successCallback, errorCallback) {
    this.postAPIRequest(
      URL_INSTALLED_APPS,
      payload,
      successCallback,
      errorCallback,
    );
  }

  /**
   * Delete installed app
   * @param {string} app_id
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  deleteInstalledApp(app_id, successCallback, errorCallback) {
    const url = serverGeneratePath(URL_INSTALLED_APP, { id: app_id });
    this.deleteAPIRequest(url, successCallback, errorCallback, [
      URL_INSTALLED_APPS,
    ]);
  }

  /**
   * Connect domain
   * @param {string} app_id
   * @param {string} domain_name
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  connectDomain(app_id, domain_name, successCallback, errorCallback) {
    const url = serverGeneratePath(URL_CONNECT_DOMAIN, { id: app_id });
    this.postAPIRequest(
      url,
      { domain_name },
      successCallback,
      errorCallback,
      "POST",
      [URL_INSTALLED_APPS],
    );
  }
}
