import { API_BASE_URL, PATH_MAIN_ROUTE } from "../core/Constants";

export const URL_INSTALLED_APPS = `${API_BASE_URL}/kube-apps-instances/`;
export const URL_AVAILABLE_APPS = `${API_BASE_URL}/kube-apps/`;
export const URL_AVAILABLE_APP = `${URL_AVAILABLE_APPS}:id/`;
export const URL_INSTALLED_APP = `${URL_INSTALLED_APPS}:id/`;
export const URL_CONNECT_DOMAIN = `${URL_INSTALLED_APP}connect-domain/`;
export const URL_AVAILABLE_APP_VERSIONS = `${API_BASE_URL}/kube-apps-versions/`;
export const URL_AVAILABLE_APPS_LOCATIONS = `${API_BASE_URL}/kube-apps-locations/`;
export const AVAILABLE_APP_TEMPLATES = `${API_BASE_URL}/kube-apps-templates/`;

export const PATH_APPS = `${PATH_MAIN_ROUTE}/apps`;
export const PATH_AVAILABLE_APPS = "available-apps";
export const PATH_INSTALLED_APPS = "installed-apps";
export const PATH_LAUNCH_AVAILABLE_APP = ":id/launch";
export const PATH_LIST_AVAILABLE_APPS = "list";
export const PATH_LIST_INSTALLED_APPS = "list";
export const FULL_PATH_LAUNCH_AVAILABLE_APP = `${PATH_APPS}/${PATH_AVAILABLE_APPS}/${PATH_LAUNCH_AVAILABLE_APP}`;
export const FULL_PATH_LIST_INSTALLED_APPS = `${PATH_APPS}/${PATH_INSTALLED_APPS}/${PATH_LIST_INSTALLED_APPS}`;
export const RELATIVE_PATH_LIST_INSTALLED_APPS = `${PATH_INSTALLED_APPS}/${PATH_LIST_INSTALLED_APPS}`;
export const RELATIVE_PATH_LIST_AVAILABLE_APPS = `${PATH_AVAILABLE_APPS}/${PATH_LIST_AVAILABLE_APPS}`;
